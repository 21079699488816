import { Button } from "@/Components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/Components/ui/card";
import HomeLayout from "@/Layouts/HomeLayout";
import { Head, Link } from "@inertiajs/react";

export default function App({ code, message }) {
    return <HomeLayout hasFooter={false} headerClass="bg-red-600">
        <Head title="Home" />
        <div className="h-[calc(100vh)] bg-primary flex flex-col justify-center items-center  max-h-screen relative w-screen home-page overflow-hidden">
            <Card className="bg-transparent shadow-none border-none">
                <CardContent className="py-8 px-4 gap-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                        <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-cyan-600 dark:text-cyan-500">{code}</h1>
                        <p className="mb-4 text-3xl tracking-tight font-bold text-red-900 md:text-4xl dark:text-white">Something's went wrong!</p>
                        <p className="mb-4 text-lg font-light text-gray-50 dark:text-gray-200">{message} </p>
                        <Link href={route('home')} >
                            <Button variant="theme">
                                Back to Homepage
                            </Button>
                        </Link>
                    </div>
                </CardContent>
            </Card>
        </div>
    </HomeLayout>
}
